import {CoreMenu} from '@core/types';

export const menuSectionGeneralAdministration: CoreMenu = {
  id: 'general',
  title: 'General admin',
  type: 'section',
  translate: 'GENERAL_ADMIN',
  children: [
    {
      id: 'user-management',
      title: 'User Management',
      translate: 'USER_MANAGEMENT',
      icon: 'users',
      type: 'item',
      url: 'admin/administration/user-management'
    },
    {
      id: 'payment',
      title: 'Course payment',
      translate: 'COURSE_PAYMENT',
      icon: 'credit-card',
      type: 'item',
      role: ['Admin'],
      url: 'admin/administration/payment-management/courses'
    }
  ]
};

export const menuSectionCourseAdministration: CoreMenu = {
  id: 'academie-admin',
  title: 'Academie admin',
  type: 'section',
  translate: 'ACADEMIE_ADMIN',
  children: [
    {
      id: 'courses',
      title: 'Courses',
      translate: 'COURSES',
      type: 'item',
      icon: 'award',
      url: 'admin/academie/courses/all'
    }
  ]
}

export const menuSectionCourseAndPostAdministration: CoreMenu = {
  id: 'academie-admin',
  title: 'Academie admin',
  type: 'section',
  translate: 'ACADEMIE_ADMIN',
  children: [
    {
      id: 'courses',
      title: 'Courses',
      translate: 'COURSES',
      type: 'item',
      icon: 'award',
      url: 'admin/academie/courses/all'
    },
    {
      id: 'posts-management',
      title: 'Posts management',
      translate: 'POST_MANAGEMENT',
      type: 'item',
      icon: 'bell',
      url: 'admin/academie/posts/posts-management'
    }
  ]
}

export const menuSectionPostsUser: CoreMenu = {
    id: 'posts',
    title: 'Posts',
    translate: 'POSTS',
    type: 'section',
    children: [
      {
        id: 'general-posts',
        title: 'General Posts',
        translate: 'GENERAL_POSTS',
        type: 'item',
        icon: 'bell',
        url: 'posts/general-posts'
      },
      {
        id: 'course-news',
        title: 'Course News',
        translate: 'COURSE_NEWS',
        type: 'item',
        icon: 'book',
        url: 'posts/course-news'
      }
    ]
}

export const menuSectionMagazineAdministration: CoreMenu = {
  id: 'magazine-admin',
  title: 'eMag admin',
  type: 'section',
  translate: 'MAGAZINE_ADMIN',
  children: [
    {
      id: 'subscriptions',
      title: 'Subscriptions',
      translate: 'SUBSCRIPTIONS',
      type: 'item',
      icon: 'calendar',
      url: '/admin/magazine/subscription'
    },
    {
      id: 'issues',
      title: 'Issues',
      translate: 'ISSUES',
      type: 'item',
      icon: 'book-open',
      url: '/admin/magazine/issues'
    },
    {
      id: 'articles',
      title: 'Articles',
      translate: 'ARTICLES',
      type: 'item',
      icon: 'file',
      url: '/admin/magazine/articles'
    },
    {
      id: 'advertisements',
      title: 'Advertisements',
      translate: 'ADVERTISEMENTS',
      type: 'item',
      icon: 'shopping-bag',
      url: '/admin/magazine/advertisements'
    },
    {
      id: 'authors',
      title: 'Authors',
      translate: 'AUTHORS',
      type: 'item',
      icon: 'pen-tool',
      url: '/admin/magazine/authors'
    }
  ]
}

export const menuSectionApprover: CoreMenu = {
  id: 'approval-management',
  title: 'Approval management',
  translate: 'APPROVAL_MANAGEMENT',
  type: 'section',
  children: [
    {
      id: 'courses-approve',
      title: 'Courses for approval',
      translate: 'COURSES_FOR_APPROVAL',
      type: 'item',
      icon: 'check-square',
      url: 'admin/academie/courses/approve',
    },
    {
      id: 'courses-approve',
      title: 'Subordinates',
      translate: 'SUBORDINATES',
      type: 'item',
      icon: 'user-check',
      url: 'admin/academie/courses/subordinates/approve',
    }
  ]
}

export const menuSectionParticipantCourse: CoreMenu = {
  id: 'courses',
  title: 'Courses',
  translate: 'COURSES',
  type: 'section',
  children: [
    {
      id: 'courses',
      title: 'Courses',
      translate: 'COURSES',
      type: 'item',
      icon: 'book-open',
      url: 'courses/all'
    }
  ]
}

export const menuSectionPublicCourses: CoreMenu = {
  id: 'courses',
  title: 'Courses',
  translate: 'COURSES',
  type: 'section',
  children: [
    {
      id: 'courses',
      title: 'Courses',
      translate: 'COURSES',
      type: 'item',
      icon: 'book-open',
      url: 'public'
    }
  ]
}
