import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PortalConfiguration} from '../models/miscellaneous/portal-configuration';
import {Observable} from 'rxjs';
import {Role} from '../models/user/role';

@Injectable({
  providedIn: 'root'
})
export class PlatformConfigurationService {

  private PLATFORM_CONFIGURATION_API = '/api/platform/configuration';

  private magazineUrl: string;

  private academieUrl: string;

  private platformUrl: string;

  constructor(private http: HttpClient) {
  }

  fetchPortalConfiguration(): void {
    this.http.get<PortalConfiguration>(this.PLATFORM_CONFIGURATION_API).subscribe(config => {
      this.magazineUrl = config.magazineUrl;
      this.academieUrl = config.academieUrl;
      this.platformUrl = config.platformUrl;
    });
  }

  subscribeForPortalConfiguration(): Observable<PortalConfiguration> {
    return this.http.get<PortalConfiguration>(this.PLATFORM_CONFIGURATION_API);
  }

  getMagazineUrl(): string {
    return this.magazineUrl;
  }

  getAcademieUrl(): string {
    return this.academieUrl;
  }

  getPlatformUrl(): string {
    return this.platformUrl;
  }

  getAllInternalUrls() {
    return ['http://localhost:4200', 'http://localhost:4201', 'http://localhost:4202',
      'https://caleidoscoop-emagazine-ui-dev.eu-central-1.elasticbeanstalk.com', 'https://caleidoscoop-platform-ui-dev.eu-central-1.elasticbeanstalk.com', 'https://lms-ui-development.eu-central-1.elasticbeanstalk.com',
      'https://caleidoscoop-emagazine-ui-test.eu-central-1.elasticbeanstalk.com', 'https://caleidoscoop-platform-ui-test.eu-central-1.elasticbeanstalk.com', 'https://lms-ui-test.eu-central-1.elasticbeanstalk.com',
      'https://caleidoscoop-emagazine-ui-production.eu-central-1.elasticbeanstalk.com', 'https://caleidoscoop-platform-ui-production.eu-central-1.elasticbeanstalk.com',
      'https://dev.emagazine.caleidoscoop.be', 'https://dev.caleidoscoop.be', 'https://dev.academie.caleidoscoop.be',
      'https://test.emagazine.caleidoscoop.be', 'https://test.caleidoscoop.be', 'https://test.academie.caleidoscoop.be',
      'https://caleidoscoop.be', 'https://academie.caleidoscoop.be', 'https://emagazine.caleidoscoop.be']
  }

  getAcademieRoles(): Role[] {
    return [Role.SUPERADMIN_SCHAARBEEK, Role.PARTICIPANT_FREE, Role.APPROVER_FREE_CLB, Role.EXTERNAL_TEACHERS,
            Role.CLB_EXTERNAL_PARTICIPANT, Role.INTERNAL_TEACHERS, Role.MANAGER_FREE_CLB, Role.PAYER_FREE_CLB, Role.LOCAL_ADMIN];
  }

  getMagazineRoles(): Role[] {
    return [Role.EMAGAZINE_ADMIN, Role.EMAGAZINE_READER];
  }
}
