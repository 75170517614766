import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {CoreMenu} from '../../types';
import {AuthService} from '../../../app/core/services/auth.service';
import {Role} from '../../../app/core/models/user/role';
import {
  menuSectionApprover, menuSectionParticipantCourse, menuSectionPostsUser,
  menuSectionCourseAdministration, menuSectionCourseAndPostAdministration,
  menuSectionGeneralAdministration, menuSectionMagazineAdministration, menuSectionPublicCourses

} from '../../../app/core/menu/menu';

@Injectable({
  providedIn: 'root'
})
export class CoreMenuService {
  onItemCollapsed: Subject<any>;
  onItemCollapseToggled: Subject<any>;

  private _onMenuChanged: BehaviorSubject<any>;
  private currentMenu: CoreMenu[] = [];

  constructor(private _router: Router,
              private authService: AuthService) {
    this.onItemCollapsed = new Subject();
    this.onItemCollapseToggled = new Subject();
    this._onMenuChanged = new BehaviorSubject(null);
  }

  get onMenuChanged(): Observable<any> {
    return this._onMenuChanged.asObservable();
  }

  getCurrentMenu(): any {
    return this.currentMenu;
  }

  addSectionToMenu(menuSection: CoreMenu): void {
    this.currentMenu.push(menuSection);
  }

  setMenu(): void {
    if (this.currentMenu.length > 0) {
      this.currentMenu = [];
    }
    if (!this.authService.isLoggedIn()) {
      this.addSectionToMenu(menuSectionPublicCourses);
      this.addSectionToMenu(menuSectionPostsUser);
      this._onMenuChanged.next({});
      return;
    }
    if (this.authService.currentUserHasRole(Role.SUPERADMIN_SCHAARBEEK)) {
      this.addSectionToMenu(menuSectionGeneralAdministration);
      this.addSectionToMenu(menuSectionCourseAndPostAdministration)
    }
    if (this.authService.currentUserHasRole(Role.LOCAL_ADMIN)) {
      this.addSectionToMenu(menuSectionCourseAdministration);
    }
    if (this.authService.currentUserHasRole(Role.APPROVER_FREE_CLB)) {
      this.addSectionToMenu(menuSectionApprover);
    }
    if (this.authService.currentUserHasRole(Role.PARTICIPANT_FREE) ||
        this.authService.currentUserHasRole(Role.CLB_EXTERNAL_PARTICIPANT)) {
      this.addSectionToMenu(menuSectionParticipantCourse);
    }
    if (this.authService.isAcademie()) {
        this.addSectionToMenu(menuSectionPostsUser);
    }

    if (this.authService.currentUserHasRole(Role.EMAGAZINE_ADMIN)) {
      this.addSectionToMenu(menuSectionMagazineAdministration);
    }

    this._onMenuChanged.next({});
  }
}
