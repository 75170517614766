import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as Waves from 'node-waves';
import {CoreMenuService} from '@core/components/core-menu/core-menu.service';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreLoadingScreenService} from '@core/services/loading-screen.service';
import {CoreTranslationService} from '@core/services/translation.service';
import {ActivatedRoute} from '@angular/router';
import {LocalizationService} from './core/services/localization.service';
import {LocalStorageService} from './core/services/local-storage.service';
import {PlatformConfigurationService} from './core/services/platform-configuration.service';
import {AuthService} from './core/services/auth.service';
import {ResponsivenessUtil} from './shared/util/responsiveness-util.service';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CookieConsentModalComponent} from './features/public/cookie-consent-modal/cookie-consent-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  coreConfig: any;
  defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'en'; // Set application default language i.e fr
  langObserver;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private responsivenessUtil: ResponsivenessUtil,
    private route: ActivatedRoute,
    private localizationService: LocalizationService,
    private localStorageService: LocalStorageService,
    public authService: AuthService,
    private platformConfigurationService: PlatformConfigurationService,
    private modalService: NgbModal
  ) {

    // // Set the main menu as our current menu
    setTimeout(() => this.setMenu(), 150);

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'nl']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('nl');
    if (localStorage.getItem('language') == undefined) {
      localStorage.setItem('language', 'nl');
    }

    if (localStorage.getItem('cookie-consent') == undefined) {
      localStorage.setItem('cookie-consent', 'false');
    }

    this._translateService.use(this.localStorageService.getLanguage());
    this.platformConfigurationService.fetchPortalConfiguration();

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Init wave effect (Ripple effect)
    Waves.init();

    this.langObserver = new BehaviorSubject<string>('nl');
    this.langObserver.subscribe(res => {
      if (res)
        this.localizationService.setLanguage(res);
    });

    this.route.queryParamMap
      .subscribe((params) => {
          let lang = params.get('lang');
          if (lang)
            this.langObserver.next(lang);
        }
      );

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

    // Set the application page title
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit() {
    this.isCookieConsentGiven();
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  private setMenu(): void {
    this._coreMenuService.setMenu();
  }

  setIsMobile() {
    this.responsivenessUtil.setIsScreenSize(window.innerWidth);
  }

  isCookieConsentGiven() {
    if (!localStorage.getItem('cookie-consent') || localStorage.getItem('cookie-consent') === 'false') {
      const ngbModalOptions: NgbModalOptions = {
        size: 'md',
        centered: true,
        backdrop: 'static',
        keyboard: false,
      };
      setTimeout(() => {
        this.modalService.open(CookieConsentModalComponent, ngbModalOptions);
      }, 1000);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setIsMobile();
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(e) {
    const internalUrls = this.platformConfigurationService.getAllInternalUrls();
    if (!internalUrls.includes(e.origin) || e.data === undefined || e.data.type === 'webpackOk') {
      return;
    }
    const payload = JSON.parse(e.data);
    if (payload.key === 'cookie-consent') {
      localStorage.setItem(payload.key, payload.data);
    }
  }
}
