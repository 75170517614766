import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-input-filter-dropdown',
  templateUrl: './input-filter-dropdown.component.html',
  styleUrls: ['./input-filter-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputFilterDropdownComponent implements OnChanges {

  @Input()
  selectedItemInternal;
  @Input()
  title: string = '';
  @Input()
  items: any[];
  @Input()
  displayField: string = ''; //use this field when working with list of objects to specify field to show in multiple select
  @Input()
  placeholder = '';
  @Input()
  customStyle: any;
  @Input()
  hasError = false;

  @Output()
  public itemSelect: Observable<any>;
  @Output()
  selectedItem = new EventEmitter<any>();
  @Output()
  searchTerm = new EventEmitter<string>();


  ngOnChanges(changes: SimpleChanges) {
    if (changes.items)
      this.itemSelect = of(changes.items.currentValue).pipe(delay(1));
  }

  selectedChanged($event: any) {
    this.selectedItem.emit($event);
  }

  onSearch($event: { term: string; items: any[] }) {
    this.searchTerm.emit($event.term);
  }
}
